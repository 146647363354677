import {
    newUser,
    editUser,
    deleteUser,
    setUsersError,
    newUserError,
    editUserError,
    deleteUserError,
    validateUserError,
    validateUserSuccess,
    signInError,
} from "../app/usersSlice";
import { toast } from "react-toastify";

const ToastMiddleware = () => (next) => (action) => {
    switch (action.type) {
        case newUser.type:
            toast.success("New user added successfully");
            break;
        case editUser.type:
            toast.success("user edited successfully");
            break;
        case deleteUser.type:
            toast.success("user deleted successfully");
            break;
        case setUsersError.type:
            toast.error("Error loading users");
            break;
        case newUserError.type:
            toast.error(
                "An Error occured while sending your informations. Please try again and if the error continues contact us"
            );
            break;
        case editUserError.type:
            toast.error("Error editing user");
            break;
        case deleteUserError.type:
            toast.error("Error deleting user");
            break;
        case signInError.type:
            toast.error(
                "Verified email not found. Please sign up and verificate your Email"
            );
            break;
        case validateUserError.type:
            toast.error(
                "Email verification is not successful. Please check your email"
            );
            break;
        case validateUserSuccess.type:
            toast.success("Email verification is successful. You can sign in now");
            break;
        default:
            break;
    }
    return next(action);
};

export default ToastMiddleware;
