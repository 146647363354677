import { createSlice, createAction } from '@reduxjs/toolkit';

export const setUsersError = createAction('setUsersError');
export const newUserError = createAction('newUserError');
export const editUserError = createAction('editUserError');
export const deleteUserError = createAction('deleteUserError');
export const validateUserError = createAction('validateUserError');
export const validateUserSuccess = createAction('validateUserSuccess');
export const signInError = createAction('signInError');



export const usersSlice = createSlice({
    name: 'users',
    initialState: {
        users: [],
    },
    reducers: {
        setUsers: (state, action) => {
            return { ...state, users: [...action.payload] };
        },
        newUser: (state, action) => {
            return { ...state, users: [action.payload, ...state.users] }
        },
        editUser: (state, action) => {
            const users = state.users.map(user => {
                if (user.id === action.payload.id) {
                    user = action.payload;
                }
                return user;
            });
            return { ...state, users: [...users] };
        },
        deleteUser: (state, action) => {
            const users = state.users.filter(user =>
                user.id !== action.payload.id);
            return { ...state, users: [...users] };
        }
    }
});

export const { setUsers, newUser, editUser, deleteUser } = usersSlice.actions;

export default usersSlice.reducer;

