import { ToastContainer } from 'react-toastify';
import UserList from './UserList';

const HomePage = () => (
    <div style={{ width: '60%', margin: 'auto' }}>
        <ToastContainer />
        <UserList />
    </div>
);

export default HomePage;