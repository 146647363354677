import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import authenticationSlice from "./authenticationSlice";
import usersSlice from "./usersSlice";
import ToastMiddleware from "../middlewares/ToastMiddleware";
import downloadSlice from "./downloadSlice";
import validationSlice from "./validationSlice";

export default configureStore({
  reducer: {
    authenticationSlice: authenticationSlice,
    usersSlice: usersSlice,
    downloadSlice: downloadSlice,
    validationSlice: validationSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(ToastMiddleware),
});
