import { createSlice } from '@reduxjs/toolkit';

export const downloadSlice = createSlice({
    name: 'downloadFile',
    initialState: {
        file: '',
    },
    reducers: {
        setDownloadFile: (state, action) => {
            return { ...state, ...{
                file: action.payload }
        }  
    }
}
});

export const { setDownloadFile } = downloadSlice.actions;

export default downloadSlice.reducer;