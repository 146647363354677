import { Button, Card, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { BrowserRouter, Route, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { validateUser } from "../app/validationSlice";
import { Validate } from "../services/validation";

const ValidationPage = () => {
   
   
  return (
    <BrowserRouter>
      <Route path="/validation/:id" children={<Validation />} />
    </BrowserRouter>
  );
};

const Validation = () => {
    const dispatch = useDispatch()
  let { id } = useParams();
  validateUser(dispatch, {id})

  return (
    <div>
      <Card border="primary" style={{ width: "30em", margin: "auto" }}>
          {/* First: {id} <br /> */}
          <Card.Header>Planarter Mail Verification</Card.Header>
          <Card.Body>
            <Card.Title>
              Confirm Your E-mail
            </Card.Title>
            <Card.Text>
            You can confirm your e-mail by clicking Verify E-mail button.
            </Card.Text>
            <div className="mb-2" style={{ margin: "auto" }}>
              <Button
                type="submit"
                variant="primary"
                style={{ margin: "auto", display: "block", width: "10rem" }}
                onClick={event => {
                  event.preventDefault();
                  Validate(dispatch, { id });
              }}
              href="/signin"
              >
                Verify E-mail
              </Button>
            </div>
          </Card.Body>
        </Card>
      <ToastContainer />
    </div>
  );
};

export default ValidationPage;
