import { React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetDownload, GetUsers, GetDownloadTurkish } from "../services/users";
import { Button, Card } from "react-bootstrap";

const UserList = () => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.usersSlice.users);
  const { username } = useSelector((state) => state.authenticationSlice);

  useEffect(() => {
    GetUsers(dispatch);
  });

  return users
    .filter((u) => u.username === username)
    .map((e) => (
      <div>
        <div key={e.id} style={{ marginBottom: "1rem" }}>
          {/* <p>{e.id}</p>
            <p>{e.username}</p> */}

          <ListRow user={e} />
        </div>
      </div>
    ));
};

const ListRow = ({ user }) => {
  const dispatch = useDispatch();



  return (
    <div>
      <Card border="primary" style={{ width: "30em", margin: "auto" }}>
        <Card.Header>Planarter Demo Download</Card.Header>
        <Card.Body>
          <Card.Title>
            Welcome {user.firstname} {user.lastname}
          </Card.Title>
          <Card.Text>
            Your email: {user.username} is verified. You can download
            Planarter Demo by clicking download button.
          </Card.Text>
          <div className="mb-2" style={{margin:'auto'}}>
            <Button onClick={() => GetDownload(dispatch)}>Download English</Button>
            <Button style={{marginLeft:'6rem'}} onClick={() => GetDownloadTurkish(dispatch)}>Türkçe İndir</Button>
          </div>
        </Card.Body>
      </Card>
    </div>
  );

  //editing operation
  // isEditing
  // ? <UserForm user={user} setIsEditing={setIsEditing} />
  // :

  // return users.map(e =>
  //     <div key={e.id} style={{ marginBottom: '1rem' }}>
  //         <ListRow user={e} />
  //     </div>
  //<Button onClick={() => GetDownloadTurkish(dispatch)}>Download Turkish</Button>
  // );
};

export default UserList;
