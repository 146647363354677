import { Nav, Button, Card } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../app/authenticationSlice';

const Navbar = () => {
    const { isLoggedIn } = useSelector(state => state.authenticationSlice);
    const dispatch = useDispatch();

    return <Nav className='navbar' style={{ backgroundColor: 'white'}}>

        <h1 style={{ fontFamily: 'Brush Script MT, cursive', display: 'flex', marginLeft:'2rem' }}><img src='Planarter_logo.png'/></h1>
        
        {isLoggedIn
            ? <Button variant='link' href='/signin' onClick={() => { dispatch(logout()) }}>Log out</Button>
            : <div style={{ display: 'flex' }}>
                <NavLink to="/signup" style={{color:'#164450'}}>Sign up</NavLink>
                <NavLink to="/signin" style={{ marginLeft: '1rem', marginRight:'2rem' ,color:'#164450'}}>Sign in</NavLink>
            </div>}       
    </Nav >
};

export default Navbar;