import { React, useState } from "react";
import { Form, Button, InputGroup, FormControl, Alert, FormGroup } from "react-bootstrap";
import { SignUp } from "../services/authentication";
import { useDispatch } from "react-redux";
import validator from "validator";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { logout } from "../app/authenticationSlice";



const SignUpPage = () => {
  const [username, setUsername] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  const validateEmail = (event) => {
    var email = event.target.value;

    if (!validator.isEmail(email)) {
      setEmailError("Please enter a valid email!");
    } else {
      setEmailError("");
    }
  };

  // const validatePhoneNumber = (event) => {
  //     var phoneNumber = event.target.value

  //     if (!validator.isMobilePhone(phoneNumber)) {
  //         setPhoneNumberError('Please enter a valid phone number!')
  //     } else {
  //         setPhoneNumberError('')
  //     }
  //   }

  return (
    <div
      style={{
        width: "30rem",
        margin: "auto",
        paddingTop: "8px",
        fontFamily: "Helvetica",
      }}
    >
      <Form
        onSubmit={(event) => {
          event.preventDefault();
          if (password === confirmPassword) {
            SignUp(dispatch, {
              username,
              firstName,
              lastName,
              companyName,
              phoneNumber,
              email,
              password,
            });
          }
          setShow(true);
        }}
      >
        <h4 style={{ textAlign: "center" }}>Create an Account / Hesap Oluştur</h4>
        <InputGroup className="mb-3">
          <FormControl
            placeholder="First Name / İsim"
            onChange={(event) => setFirstName(event.target.value)}
            required
          />
        </InputGroup>
        <InputGroup className="mb-3">
          <FormControl
            placeholder="Last Name / Soyisim"
            onChange={(event) => setLastName(event.target.value)}
            required
          />
        </InputGroup>
        <InputGroup className="mb-3">
          <FormControl
            placeholder="Company Name / Şirket Adı"
            onChange={(event) => setCompanyName(event.target.value)}
          />
        </InputGroup>
        {/* <InputGroup className='mb-3'>
                <FormControl placeholder='Phone Number'
                    onChange={event => {setPhoneNumber(event.target.value); validatePhoneNumber(event)}} />
            </InputGroup>
            <div>{phoneNumberError}</div> */}
        <InputGroup className="mb-3">
          <PhoneInput
            defaultCountry="TR"
            placeholder="Phone Number / Telefon"
            value={phoneNumber}
            onChange={setPhoneNumber}
            required
          />
        </InputGroup>
        <InputGroup className="mb-3">
          <FormControl
            placeholder="Email / Eposta"
            onChange={(event) => {
              setUsername(event.target.value);
              setEmail(event.target.value);
              validateEmail(event);
            }}
            required
          />
        </InputGroup>
        <div>{emailError}</div>
        <InputGroup className="mb-3">
          <FormControl
            placeholder="Password / Şifre"
            type="password"
            onChange={(event) => setPassword(event.target.value)}
          />
        </InputGroup>
        <InputGroup className="mb-3">
          <FormControl
            placeholder="Confirm Password / Şifreyi Doğrulayın"
            type="password"
            onChange={(event) => setConfirmPassword(event.target.value)}
          />
        </InputGroup>  
        <FormGroup className="mb-3" controlId="formBasicCheckbox">
         
      
          <Form.Label>
            <ul>
              <li> To create an account you should agree{" "}
                  <a href="https://planarter.com/kullaniciSozlesmesi.html" target="_blank">User Agreement</a> and acknowledge reading our{" "}
                  <a href="https://planarter.com/kvkkAydinlatmaMetni.html" target="_blank">User Privacy Notice.</a></li>
              <li>Hesap oluşturmak için{" "} 
                <a href="https://planarter.com/kullaniciSozlesmesi.html" target="_blank">Kullanıcı Sözleşmesi</a>'ni ve{" "} 
                <a href="https://planarter.com/kvkkAydinlatmaMetni.html" target="_blank">Kişisel Verilerin Korunması ve İşlenmesi Politikası</a>'nı 
                kabul etmelisiniz.</li>
            </ul>
          </Form.Label>
          <Form.Check
            required
            placeholder="KVKK Onay"
            label="Kabul ediyorum / I accept."
            feedback="You must agree before submitting."
            feedbackType="invalid"
          ></Form.Check>
        </FormGroup>

        <Button
          type="submit"
          variant="success"
          style={{
            margin: "auto",
            display: "block",
            backgroundColor: " #124265",
            width: "10rem",
          }}
          disabled={
            password !== confirmPassword ||
            password.length < 1 ||
            !validator.isEmail(email)
          }
          // onClick={() => setShow(true)}
        >
          Sign Up
        </Button>
        <Alert show={show} variant="primary">
          <Alert.Heading>
            Hello {firstName} {lastName}
          </Alert.Heading>
          <p>
            Verification Email has been sent to your Email address. Please click
            the link on the e-mail and verify your Email. Then you can download
            Planarter Demo Edition.
          </p>
          <hr />
          <div className="d-flex justify-content-end">
            <Button
              onClick={() => dispatch(logout())}
              variant="outline-danger"
              href="/signin"
            >
              Close & Log out
            </Button>
          </div>
        </Alert>

        {/* !isValidPhoneNumber(phoneNumberValue) */}
        {/* !validator.isMobilePhone(phoneNumber)  */}

        {/* onClick={setEmailError} */}
      </Form>
    </div>
  );
};

export default SignUpPage;
