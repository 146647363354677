import { createSlice } from '@reduxjs/toolkit';

export const validationSlice = createSlice(
    {
        name: 'validateUser',
        initialState: {
            id: '',
        },
        reducers:{
            validateUser: (state, action) => {
                return {...state, id:action.payload};
            }
        }
    }
);

export const {validateUser} = validationSlice.actions;
export default validationSlice.reducer;