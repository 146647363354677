import * as axios from 'axios';

import { validateUserError, validateUserSuccess } from '../app/usersSlice';


const axiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL}`,
});



export const Validate = async (dispatch, {id}) => {
          
    try {     
        
        // api call
        await axiosInstance.put('/validation/'+ id, {id});
        dispatch(validateUserSuccess());
    } catch {
        dispatch(validateUserError());       
    }

}