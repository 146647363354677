import {
    setUsersError, editUserError, newUserError, deleteUserError, 
    setUsers, newUser, editUser, deleteUser
} from '../app/usersSlice';
import * as axios from 'axios';
import { saveAs } from "file-saver";

const axiosInstance = axios.create({    
    baseURL: `${process.env.REACT_APP_BASE_URL}/Demousers`,
})

axiosInstance.interceptors.request.use((config) => {
    config.headers = { authorization: 'Bearer ' + sessionStorage.getItem('token') };
    return config;
});

export const GetUsers = async (dispatch) => {
    try {
        // api call
        const { data } = await axiosInstance.get();
        dispatch(setUsers(data));
    } catch {
        dispatch(setUsersError());
    }
}

export const GetDownload = async (dispatch) =>{
    await axiosInstance.get( `${process.env.REACT_APP_BASE_URL}/download`, {
      
          responseType: 'blob'
        }
      )
    // .then((response) => response.blob())
    // .then((blob) => {
 
        .then((response) => {
            saveAs(response.data, 'Planarter.zip');
//   // 2. Create blob link to download
//   const url = window.URL.createObjectURL(new Blob([response.data]));
//   const link = document.createElement('a');
//   link.href = url;
//   link.setAttribute('download', 'Planarter.png');
//   // 3. Append to html page
//   document.body.appendChild(link);
//   // 4. Force download
//   link.click();
//   // 5. Clean up and remove the link
//   link.parentNode.removeChild(link);
//     // .then(resp => {
//     //     dispatch(setDownloadFile(resp.data));
//     //     console.log(resp.data);
    });
}

export const GetDownloadTurkish = async (dispatch) =>{
    await axiosInstance.get(`${process.env.REACT_APP_BASE_URL}/download/tr`, {
      
          responseType: 'blob'
        }
      )
        .then((response) => {
            saveAs(response.data, 'PlanarterTR.zip');
    });
}

export const NewUser = async (dispatch, user) => {
    try {
        // api call
        const { data } = await axiosInstance.post('', user);
        dispatch(newUser(data));
    } catch {
        dispatch(newUserError());
    }
}

export const EditUser = async (dispatch, user) => {
    try {
        // api call
        await axiosInstance.put('', user);
        dispatch(editUser(user));
    } catch {
        dispatch(editUserError());
    }
}

export const DeleteUser = async (dispatch, user) => {
    try {
        // api call
        await axiosInstance.delete('', { data: { ...user } });
        dispatch(deleteUser(user));
    } catch {
        dispatch(deleteUserError());
    }
}