import { React, useState } from 'react';
import { Form, Button, InputGroup, FormControl } from 'react-bootstrap';
import { SignIn } from '../services/authentication';
import { useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';

const SignInPage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const dispatch = useDispatch();

    return <div style={{ width: '30rem', margin: 'auto', paddingTop: '8px' }}>
        <Form
            onSubmit={event => {
                event.preventDefault();
                SignIn(dispatch, { username, password });
            }}>
            <h4 style={{ textAlign: 'center' }}>Planarter Sign In / Giriş Yap</h4>
            <InputGroup className='mb-3'>
                <FormControl placeholder='Email / E-posta'
                    onChange={event => setUsername(event.target.value)} />
            </InputGroup>
            <InputGroup className='mb-3'>
                <FormControl placeholder='Password / Şifre' type='password'
                    onChange={event => setPassword(event.target.value)} />
            </InputGroup>
            <Button type='submit' variant='primary' 
            style={{ margin: 'auto', display: 'block', width: '10rem', backgroundColor: '#164450'}}>Sign In</Button>
            <ToastContainer/>
        </Form>
    </div>
};

export default SignInPage;
